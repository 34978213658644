import React from 'react'
import { ResponsiveLayout } from '@ashleynexvelsolutions/responsive-layout'
import { Layout } from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <ResponsiveLayout>
      <div className="mt-20 mb-96">
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </ResponsiveLayout>
  </Layout>
)

export default NotFoundPage
